import styled from "styled-components";
import Dropdown from "react-bootstrap/Dropdown";
import {theme} from "../../utils/theme";

export const DropdownItem = styled(Dropdown.Item)`
    font-family: ${theme.fonts.main};
    font-weight: 700;
    text-decoration: none;
    color: ${theme.colors.black};
    padding: 6px 0 6px 0;
    text-align: center;
    
    &:focus, &:hover {
        background-color: ${theme.colors.orange1};
        color: ${theme.colors.white};
    }
    
    ${theme.media.phoneSmall} {
        font-size: 12px;
    }
    
    ${theme.media.tabletLarge} {
        font-size: 14px;
    }
`;
