import styled from "styled-components";
import Dropdown from "react-bootstrap/Dropdown";
import {theme} from "../../utils/theme";

export const DropdownToggle = styled(Dropdown.Toggle)`
    font-weight: 700;
    font-family: ${theme.fonts.main};
    color: ${theme.colors.black};
    border: 2px solid ${theme.colors.orange1};
    text-align: left;
    background-color: ${theme.colors.white};
    text-transform: uppercase;
    border-radius: 5px;
    
    &:focus {
        outline: none;
    }  
  
    &:hover {
        cursor: pointer;
    }  
    
    ${theme.media.phoneSmall} {
        height: 25px;
        width: 45px;
        font-size: 12px;
        padding-left: 5px;
    }
    
    ${theme.media.tabletLarge} {
        width: 55px;
        height: 35px;
        font-size: 14px;
        padding-left: 10px;
    }
`;
