import styled from 'styled-components';
import {theme} from '../../utils/theme';

export const H1 = styled.h1`
    font-family: ${theme.fonts.main};
    font-weight: 400;
    text-align: center;
    color: ${theme.colors.orange1};
    
    ${theme.media.phoneSmall}{
        font-size: 22px;
    }
    
    ${theme.media.phoneLarge} {
        font-size: 24px;
    }
    
    ${theme.media.tabletSmall} {
        font-size: 26px;
    }
    
    ${theme.media.desktop} {
        font-size: 34px;
    }
`;
