import styled from "styled-components";
import {theme} from "../../utils/theme";

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 0;
    height: auto;
    margin: 0 auto;
    
    ${theme.media.phoneSmall}{
        width: ${theme.sizes.mainContainerWidthPhoneSmall};
    }
    
    ${theme.media.phoneLarge}{
        width: ${theme.sizes.mainContainerWidthPhoneLarge};
    }
    
    ${theme.media.tabletSmall}{
        width: ${theme.sizes.mainContainerWidthTabletSmall};
    }
    
    ${theme.media.tabletLarge}{
        width: ${theme.sizes.mainContainerWidthTabletLarge};
    }
    
    ${theme.media.desktop}{
        width: ${theme.sizes.mainContainerWidthDesktop};
    }
`;
