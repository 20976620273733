import styled from "styled-components";
import Dropdown from "react-bootstrap/Dropdown";
import {theme} from "../../utils/theme";

export const DropdownMenu = styled(Dropdown.Menu)`
    display: flex;
    flex-direction: column;
    border: 2px solid ${theme.colors.orange1};
    z-index: 100;
    padding: 5px 0;
    background-color: ${theme.colors.white};
    margin: 1px 0;
    text-transform: uppercase;
    border-radius: 5px;
    
    ${theme.media.phoneSmall} {
        width: 45px;
    }
    
    ${theme.media.tabletLarge} {
        width: 55px;
    }
`;
