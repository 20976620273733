import styled from "styled-components";
import {theme} from "../../utils/theme";

export const Button = styled.button`
    border: none;
    color: ${theme.colors.white};
    font-size: 18px;
    font-family: ${theme.fonts.main};
    text-decoration: none;
    padding: 12px 0;
    width: 80%;
    text-align: center;
    outline: none;
    border-radius: 5px;
    background-size: 200%;
    background-image: linear-gradient(to left, ${theme.colors.orange2}, ${theme.colors.orange3}, ${theme.colors.orange2});
    transition: .3s;
    
    :hover {
        cursor: pointer;
        background-position: right;
    }
    
    ${theme.media.phoneSmall} {
        width: 60%;
        font-size: 14px;
    }
    
    ${theme.media.desktop} {
        width: 25%;
        font-size: 16px;
    }
`;
