export const colors = {
    orange1: '#F59800',
    orange2: '#FF8F4D',
    orange3: '#FFB841',
    black: '#000',
    white: '#fff',
    grey1: '#999',
    grey2: '#71767a;',
    transparent: 'transparent',
    red: '#FF0200'
};
