import { colors } from './colors/colors';
import { fonts } from './fonts/fonts';
import { media } from './media/media';
import { sizes } from "./sizes/sizes";

export const theme = {
    colors,
    fonts,
    media,
    sizes
};
